<template>
	<CRow>
		<CCol sm="6" lg="3" v-if="showMember">
			<CWidgetDropdown color="primary" :header="this.showHeaderMembers" :text="this.$t('pages.dashboard.members')">
				<template #footer>
					<div style="height:30px; text-align:center;">
						<slot name="back">
							<small>
								<a href="javascript:void(0)" @click="goUsermanagementPage" class="text-white">
									{{$t('common.moreInfo')}}
									<CIcon name="cil-arrow-circle-right"></CIcon>
								</a>
							</small>
						</slot>
					</div>
				</template>
			</CWidgetDropdown>
		</CCol>
		<CCol sm="6" lg="3" v-if="showSaleman">
			<CWidgetDropdown color="primary" :header="this.showHeaderSales" :text="this.$t('pages.manager.salesman')">
				<template #footer>
					<div style="height:30px; text-align:center;">
						<slot name="back">
							<small>
								<a href="javascript:void(0)" @click="goSalesmanPage" class="text-white">
									{{$t('common.moreInfo')}}
									<CIcon name="cil-arrow-circle-right"></CIcon>
								</a>
							</small>
						</slot>
					</div>
				</template>
			</CWidgetDropdown>
		</CCol>
		<CCol sm="6" lg="3" v-if="showAgent">
			<CWidgetDropdown color="info" :header="this.showHeaderAgents" :text="this.$t('pages.dashboard.agents')">
				<template #footer>
					<div style="height:30px; text-align:center;">
						<slot name="back">
							<small>
								<a href="javascript:void(0)" @click="goAgentPage" class="text-white">
									{{$t('common.moreInfo')}}
									<CIcon name="cil-arrow-circle-right"></CIcon>
								</a>
							</small>
						</slot>
					</div>
				</template>
			</CWidgetDropdown>
		</CCol>
		<CCol sm="6" lg="3" v-if="showProductList == true">
			<CWidgetDropdown color="warning" :header="this.showHeaderProducts" :text="this.$t('pages.dashboard.products')">
				<template #footer>
					<div style="height:30px; text-align:center;">
						<slot name="back">
							<small>
								<a href="javascript:void(0)" @click="goProductsPage" class="text-white">
									{{$t('common.moreInfo')}}
									<CIcon name="cil-arrow-circle-right"></CIcon>
								</a>
							</small>
						</slot>
					</div>
				</template>
			</CWidgetDropdown>
		</CCol>
		<CCol sm="6" lg="3" v-if="showWholeOders">
			<CWidgetDropdown color="success" :header="this.showHeaderWholesaleorders" :text="this.$t('pages.dashboard.wholesaleorders')">
				<template #footer>
					<div style="height:30px; text-align:center;">
						<slot name="back">
							<small>
								<a href="javascript:void(0)" @click="goWholesaleOdersPage" class="text-white">
									{{$t('common.moreInfo')}}
									<CIcon name="cil-arrow-circle-right"></CIcon>
								</a>
							</small>
						</slot>
					</div>
				</template>
			</CWidgetDropdown>
		</CCol>
		<CCol sm="6" lg="3">
			<CWidgetDropdown color="danger" :header="this.showHeaderOrders" :text="this.$t('pages.dashboard.retailOrders')">
				<template #footer>
					<div style="height:30px; text-align:center;">
						<slot name="back">
							<small>
								<a href="javascript:void(0)" @click="goOdersPage" class="text-white">
									{{$t('common.moreInfo')}}
									<CIcon name="cil-arrow-circle-right"></CIcon>
								</a>
							</small>
						</slot>
					</div>
				</template>
			</CWidgetDropdown>
		</CCol>
	</CRow>
</template>

<script>
	import i18n from '@/plugins/i18n'
	import { mapGetters } from "vuex";

    import SearchDashboardModel from '@/models/SearchDashboardModel';
   	
	export default {
		name: 'WidgetsDropdown',
		props: {
			data: {
				default: new SearchDashboardModel(),
				type: Object
			},
            showProductList: {
                default: true,
                type: Boolean
            },
		},
		data() {
			return {
                showAgent: false,
				showSaleman: false,
				showMember: true,
				showWholeOders: false
			}
		},
		computed: {			
			...mapGetters('auth', ['user']),

            showHeaderMembers () {              
                return this.$func.formatNumber(this.data.members);
			},
            showHeaderAgents() {                
                return this.$func.formatNumber(this.data.agents);
			},
            showHeaderProducts() {               
                return this.$func.formatNumber(this.data.products);
			},
			showHeaderOrders() {               
                return this.$func.formatNumber(this.data.orders);
			},
            showHeaderSales() {
                return this.$func.formatNumber(this.data.salesman);
			},
            showHeaderWholesaleorders() {
                return this.$func.formatNumber(this.data.wholesaleorders);
            },
		},
        methods: {
            goUsermanagementPage() {
                this.$router.push('/users/list');
			},
            goAgentPage() {
                this.$router.push('/agents/list');
			},
            goProductsPage() {
                this.$router.push('/products/list');
			},
            goOdersPage() {
                this.$router.push('/orders');
            },
            goSalesmanPage() {
                this.$router.push('/salesman/list');
			},
			goWholesaleOdersPage() {
                if (this.user != null && this.user.role === 'admin')
					this.$router.push('/warehouses/transferlist');
				else
                    this.$router.push('/warehouses/receivelist');
			}
		},
        created() {
            //this.onSearch();
			let role = '';
			if (this.user != null)
				role = this.user.role;

            if (role === 'admin') {
                this.showWholeOders = true;
            }
			else if (role === 'supervisor') {
				this.showSaleman = false;
			}
			else if (role === 'sale') {
                this.showAgent = true;		
                this.showWholeOders = true;
			}
            else if (role === 'agent') {
                this.showMember = false;
            }
        },
	}
</script>
