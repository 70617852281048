<template>
    <CCard>
        <CCardHeader>{{ this.title }}</CCardHeader>
        <CCardBody>
            <CChartBar :datasets="bindDataToChart" :labels="showLabels" />
        </CCardBody>
        <CCardFooter>{{ showFooter }}</CCardFooter>
    </CCard>   
</template>

<script>
	import { CChartBar } from '@coreui/vue-chartjs'
	import i18n from '@/plugins/i18n'

	export default {
        name: 'ChartOrders_2',
        props: {
            data: {
                default: null,
                type: Array
            },
            title: {
                default: i18n.t('pages.dashboard.ordersChart'),
                type: String
            },
            subtitle: {
                default: i18n.t('pages.dashboard.retailOrders'),
                type: String
            },
            backgroundColor: {
                default: '2eb85c',
                type: String
            },
            labels: {
                default: null,
                type: Array
            },
		},
        components: {          
            CChartBar
        },       
		computed: {						
            bindDataToChart () {              
                return [
                    {
                        label: this.subtitle,
                        backgroundColor: '#' + this.backgroundColor,
                        data: this.data,
                    }
                ];
            },
            showLabels() {
                return this.labels;                               
            },
            showFooter() {               
                return (new Date().getFullYear()).toString();
            }
		}		
	}
</script>
