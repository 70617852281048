<template>
    <div>
        <CCard>
            <CCardHeader></CCardHeader>
            <CCardBody>
                <WidgetsDropdown :data="this.dashboardQuickViewInfo" :showProductList="isShowProductList" />
            </CCardBody>
        </CCard>        
        <!--<CCardGroup columns class="card-columns cols-2">
            <CCard>
                <CCardHeader> {{ $t('pages.dashboard.dashboardPointInSystem') }}</CCardHeader>
                <CCardBody>
                    <div>
                        <span style="padding-left:5px;color:crimson;font-weight:bold">{{ $t('pages.dashboard.totalPoints') }}: {{ $func.formatNumber(totalPoints) }}</span>
                    </div>
                    <apexchart type="pie" width="380" :options="chartOptions" :series="dataOfDashboardPoints"></apexchart>
                </CCardBody>
            </CCard>
            <CCard>
                <CCardHeader> {{ $t('pages.dashboard.stockDashboard') }}</CCardHeader>
                <CCardBody>
                    <div>
                        <span style="padding-left:5px;color:crimson;font-weight:bold">{{ $t('pages.dashboard.totalNumOfStock') }}: {{ $func.formatNumber(totalStocks) }}</span>
                    </div>
                    <apexchart type="pie" width="380" :options="chartStockOptions" :series="dataOfDashboardStocks"></apexchart>
                </CCardBody>
            </CCard>
        </CCardGroup>-->

        <CCardGroup columns class="card-columns cols-2">
            <ChartOrders :data="this.dashboardOrders" :title="$t('pages.dashboard.retailorderChartByMonth')" :subtitle="$t('pages.dashboard.retailOrders')" />
            <ChartOrders :data="this.dashboardWholesaleOrders" :title="$t('pages.dashboard.wholesaleorderChartByMonth')" :subtitle="$t('pages.dashboard.wholesaleorders')" :backgroundColor="'1e85e1'" />
        </CCardGroup>

        <CCardGroup columns class="card-columns cols-2">
            <ChartOrders_2 :data="this.dashboardOrdersLast7Days" :title="$t('pages.dashboard.retailorderChartLast7Day')" :subtitle="$t('pages.dashboard.retailOrders')" :backgroundColor="'e68819'" :labels="this.labelRetailOrdersLastTime" />
            <ChartOrders_2 :data="this.dashboardWholesaleOrdersLast7Days" :title="$t('pages.dashboard.wholesaleorderChartLast7Day')" :subtitle="$t('pages.dashboard.wholesaleorders')" :backgroundColor="'c23d7d'" :labels="this.labelWholesaleOrdersLastTime" />
        </CCardGroup>

    </div>    
</template>

<script>    
    import { mapState, mapActions, mapGetters } from 'vuex'
    import WidgetsDropdown from './widgets/WidgetsDropdown'
    import ChartOrders from './widgets/ChartOrders'
    import ChartOrders_2 from './widgets/ChartOrders_2'
    import VueApexCharts from 'vue-apexcharts'
    import i18n from '@/plugins/i18n'
   
    export default {
        name: 'Dashboard',
        data() {
            return {                                
                isShowProductList: true,
                labelLast7days: [],                                             
                //chartOptions: {
                //    chart: {
                //        width: 380,
                //        type: 'pie',
                //    },
                //    labels: [i18n.t('pages.dashboard.pointOfPurchase'), i18n.t('pages.deposit.rewardPoints'), i18n.t('pages.dashboard.pointOfProfit'), i18n.t('pages.withdrawal.withdrawpoints')],
                //    responsive: [{
                //        breakpoint: 480,
                //        options: {
                //            chart: {
                //                width: 200
                //            },
                //            legend: {
                //                position: 'bottom'
                //            }
                //        }
                //    }]
                //},
                //chartStockOptions: {
                //    chart: {
                //        width: 380,
                //        type: 'pie',
                //    },
                //    labels: [i18n.t('pages.dashboard.numberOfStockAvaiable'), i18n.t('pages.dashboard.numberOfStockSold'), i18n.t('pages.dashboard.numberOfStockBeingTraded')],
                //    responsive: [{
                //        breakpoint: 480,
                //        options: {
                //            chart: {
                //                width: 200
                //            },
                //            legend: {
                //                position: 'bottom'
                //            }
                //        }
                //    }]
                //},
            }
        },
        components: {
            WidgetsDropdown,                      
            ChartOrders,
            ChartOrders_2,
            //VueApexCharts
            //apexchart: VueApexCharts
        },
        computed: {           
            //...mapState('dashboard', ['dashboardQuickViewInfo', 'dashboardOrders', 'dashboardWholesaleOrders', 'dashboardOrdersLast7Days', 'dashboardWholesaleOrdersLast7Days', 'labelRetailOrdersLastTime'
            //    , 'labelWholesaleOrdersLastTime', 'dataOfDashboardPoints', 'totalPoints', 'totalStocks','dataOfDashboardStocks']),
            ...mapState('dashboard', ['dashboardQuickViewInfo', 'dashboardOrders', 'dashboardWholesaleOrders', 'dashboardOrdersLast7Days', 'dashboardWholesaleOrdersLast7Days', 'labelRetailOrdersLastTime'
                , 'labelWholesaleOrdersLastTime']),
            ...mapGetters('auth', ['user']),
        },
       
        methods: {
            //...mapActions('dashboard', ['getDashboardQuickViewInfo', 'getTotalOrders', 'getWholesaleOrderForDashboard', 'getTotalOrdersLat7Days', 'getWholesaleOrderForDashboardLat7Days',
            //    'getDataOfPointsToShowDashboard','getStockData']),
            ...mapActions('dashboard', ['getDashboardQuickViewInfo', 'getTotalOrders', 'getWholesaleOrderForDashboard', 'getTotalOrdersLat7Days', 'getWholesaleOrderForDashboardLat7Days']),
        },
        mounted() {            
            this.getDashboardQuickViewInfo();
            this.getWholesaleOrderForDashboard();
            this.getTotalOrdersLat7Days();
            this.getWholesaleOrderForDashboardLat7Days();
            //this.getDataOfPointsToShowDashboard();   
            //this.getStockData();
        },
        created() {
            this.getTotalOrders();            
            if (this.$store.state.auth.user.role !== 'admin') {
                this.isShowProductList = false;
            }
        }
    }
</script>
