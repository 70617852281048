<template>
    <CCard>
        <CCardHeader>{{ this.title }}</CCardHeader>
        <CCardBody>
            <CChartBar :datasets="bindDataToChart" :labels="showLabels" />
        </CCardBody>
        <CCardFooter>{{ $t('pages.dashboard.chartFooter') }} {{ new Date().getFullYear() }}</CCardFooter>
    </CCard>   
</template>

<script>
	import { CChartBar } from '@coreui/vue-chartjs'
	import i18n from '@/plugins/i18n'

	export default {
		name: 'ChartOrders',
        props: {
            data: {
                default: null,
                type: Array
            },
            title: {
                default: i18n.t('pages.dashboard.ordersChart'),
                type: String
            },
            subtitle: {
                default: i18n.t('pages.dashboard.retailOrders'),
                type: String
            },
            backgroundColor: {
                default: '2eb85c',
                type: String
            }
		},
        components: {          
            CChartBar
        },       
		computed: {						
            bindDataToChart () {              
                return [
                    {
                        label: this.subtitle,
                        backgroundColor: '#' + this.backgroundColor,
                        data: this.data,
                    }
                ];
            },
            showLabels() {               
                let sCuryear = new Date().getFullYear().toString();
                return [
                    ' 01 - ' + sCuryear,
                    ' 02 - ' + sCuryear,
                    ' 03 - ' + sCuryear,
                    ' 04 - ' + sCuryear,
                    ' 05 - ' + sCuryear,
                    ' 06 - ' + sCuryear,
                    ' 07 - ' + sCuryear,
                    ' 08 - ' + sCuryear,
                    ' 09 - ' + sCuryear,
                    ' 10 - ' + sCuryear,
                    ' 11 - ' + sCuryear,
                    ' 12 - ' + sCuryear
                ];
            }
		}		
	}
</script>
